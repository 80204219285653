import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, IconButton, Collapse } from "@mui/material";
import { Card, CardContent, CardHeader } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const colors = {
  create: "tracesType.create",
  update: "tracesType.update",
  delete: "tracesType.delete",
};

const CardTrace = ({ trace }) => {
  const [expanded, setExpanded] = useState(false);
  const [date, setDate] = useState("");
  const [color] = useState(colors[trace.type]);
  const { t } = useTranslation();

  const types = {
    create: t("tracesHistory:createFlow"),
    update: t("tracesHistory:updateFlow"),
    delete: t("tracesHistory:deleteFlow"),
    edge: t("tracesHistory:edgeFlow"),
    page: t("tracesHistory:pageFlow"),
  };

  useEffect(() => {
    let date = new Date(trace.date);
    date = date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    setDate(date);
  }, [trace]);

  const normalizeName = (name) => {
    if (name.includes("-")) {
      name = name.replace(/-/g, " ");
    }
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  };

  function renderTitle(trace, types) {
    return (
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        {trace.type === "update" ? (
          <React.Fragment>
            <Typography>
              {types[trace.type] + types[trace.collectionName]}
            </Typography>
            <Typography sx={{ color: "tracesName.new", marginLeft: 0.5 }}>
              {normalizeName(trace.changes.label)}
            </Typography>
          </React.Fragment>
        ) : trace.changes.id && trace.type !== "create" ? (
          <Typography>
            {types[trace.type] +
              types[trace.collectionName] +
              normalizeName(trace.changes.label)}
          </Typography>
        ) : trace.changes.label && trace.type === "create" ? (
          <React.Fragment>
            <Typography>
              {types[trace.type] + types[trace.collectionName]}
            </Typography>
            <Typography sx={{ color: "tracesName.new", marginLeft: 0.5 }}>
              {normalizeName(trace.changes.label)}
            </Typography>
          </React.Fragment>
        ) : null}
      </div>
    );
  }

  return (
    <Card
      sx={{
        width: "95%",
        marginTop: 1,
        marginLeft: "2.5%",
        boxShadow: "4",
        borderRadius: "5px",
      }}>
      <CardHeader
        avatar={
          <Box
            sx={{
              borderColor: "black",
              borderStyle: "solid",
              borderWidth: 1,
              width: 20,
              height: 20,
              bgcolor: color,
              borderRadius: "50%",
            }}
          />
        }
        title={renderTitle(trace, types)}
        subheader={
          <>
            {date}
            <span style={{ marginLeft: "55px" }}></span>
            {trace.userName}
          </>
        }
        action={
          <div>
            {trace.type !== "delete" &&
            !(
              Object.keys(trace.changes)?.length === 2 && trace.changes.name
            ) ? (
                <ExpandMore
                  expand={expanded}
                  onClick={() => setExpanded(!expanded)}
                  aria-label="show more">
                  <ExpandMoreIcon />
                </ExpandMore>
              ) : null}
          </div>
        }></CardHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {trace.type !== "delete" ? (
            <div>
              {Object.entries(trace.changes).map(([key, value]) => (
                <div key={key}>
                  {key}:
                  {value === null ? (
                    "no changes"
                  ) : typeof value === "object" ? (
                    <div>
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <div key={subKey}>
                          {subKey}:{" "}
                          {subValue === null ? "deleted" : subValue.toString()}
                        </div>
                      ))}
                    </div>
                  ) : (
                    value
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CardTrace;
