// 1. External library imports
import React, { useEffect, useMemo, useState, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
} from "reactflow";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// 2. Internal module imports
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import { defaultAgent } from "../shared/helper/agentsHelper";
import { get, post, remove } from "../shared/http/httpService";

// 3. Component imports
import Bar from "./Components/Bar.component";
import CustomEdge from "./Components/EdgeComponents/CustomEdge.component";
import "./Flowgraph-V2.component.css";
import NodeModal from "../Flowgraph/Components/nodeModal.component";
import EdgeModalTabsPanel from "./Components/EdgeComponents/EdgeModalTabsPanel.component";
import TracesHistory from "../shared/components/CardTraceFlow/TracesHistory.component";

const colors = {
  default: "#ff4000",
  start: "#0050ff",
  quickreplies: "#ff0073",
  trasnfer: "#f87171",
  other: "#a845d0",
};

const getId = () => `randomnode_${+new Date()}`;

export default function FlowgraphV2() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [agentSelected, setAgentSelected] = useState("");
  const [agentNames, setAgentNames] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [searchCoords, setSearchCoords] = useState({ x: 0, y: 0 });
  const [editEdge, setEditEdge] = useState({ data: {} });
  const [editNode, setEditNode] = useState(null);
  const [openNodeModal, setOpenNodeModal] = useState(false);
  const [openEdgeModal, setOpenEdgeModal] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [endpoints, setEndpoints] = useState([]);
  const [previous, setPrevious] = useState({});
  const [pagesLean, setPagesLean] = useState([]);
  const [intents, setIntents] = useState([]);
  const [traces, setTraces] = useState([]);
  const [intentsqr, setIntentsqr] = useState([]);
  const [edgeSelectedTab, setEdgeSelectedTab] = useState(0);
  const [deleteElements, setDeleteElements] = useState({
    pages: [],
    edges: [],
  });
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  const edgeTypes = useMemo(
    () => ({
      smoothstep: (props) => (
        <CustomEdge
          {...props}
          onEdgeSelectedTab={(index) => setEdgeSelectedTab(index)}
        />
      ),
      custom: CustomEdge,
    }),
    [],
  );

  const zoomIn = useCallback(() => {
    reactFlowInstance.zoomIn({ duration: 500 });
  }, [reactFlowInstance]);

  const zoomOut = useCallback(() => {
    reactFlowInstance.zoomOut({ duration: 500 });
  }, [reactFlowInstance]);

  useEffect(() => {
    if (agentSelected !== "") {
      getPages();
      getAllPages();
      getIntents();
      getTraces();
      getUserPermisions();
    }
  }, [agentSelected]);

  const getAgentNames = () => {
    get("/agents")
      .then(async (data) => {
        let newData = orderAlphabeticallyWithAttribute(
          data.agents,
          "display_name",
        );
        let default_agent = defaultAgent(newData);
        //TODO: get agent config
        //let agentConfig = [];
        //  newData.forEach((agent) => {
        //    if (agent.name === default_agent.name) {
        //      agentConfig = agent.vars;
        //    }
        //  });
        setAgentNames(newData);
        setAgentSelected(default_agent.name);
        //setAgentConfig(agentConfig);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const baseStyle = useMemo(
    () => ({
      background: colors.default,
      borderRadius: 10,
      fontSize: 15,
      color: "white",
      fontFamily: "Roboto",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.6)",
    }),
    [],
  );

  const hasQuickReplies = (node) =>
    (node.data?.quickReplies && node.data?.quickReplies.length > 0) ||
    (node.data?.quickRepliesDynamic &&
      node.data?.quickRepliesDynamic.length > 0);

  const customNodeStyles = useCallback(
    (node) => {
      let style = { ...baseStyle };

      if (hasQuickReplies(node)) {
        style.background = colors.quickreplies;
      }

      if (node.data?.core_transfer_page) {
        style.background = colors.trasnfer;
      }

      if (node.data?.transferToAgent?.agent) {
        style.background = colors.other;
        node.type = "output";
      }

      if (node.node_type === "input") {
        style.background = colors.start;
        node.type = "input";
      }

      if (node.data?.label === "End Session") {
        style.background = colors.start;
        node.type = "output";
      }

      return style;
    },
    [baseStyle],
  );

  //funcion para nombre del nuevo nodo si ya existe new node
  const getNewNodeName = (nodes) => {
    let name = "New node";
    const existingName = nodes.some((node) => node.data.label === name);

    if (existingName) {
      let i = 1;
      const si = true;
      while (si) {
        const newName = `${name} (${i})`;
        const existingName = nodes.some((node) => node.data.label === newName);
        if (!existingName) {
          name = newName;
          break;
        }
        i++;
      }
    }
    return name;
  };

  const getPages = useCallback(() => {
    get("/flows?agent=" + agentSelected).then(async (data) => {
      let newNodes = [];
      data.nodes.forEach((node) => {
        //if input node, then set coords
        if (node.node_type === "input") {
          setSearchCoords({ x: node.position.x, y: node.position.y });
        }

        let styleTmp = customNodeStyles(node);
        newNodes.push({
          ...node,
          style: styleTmp,
          sourcePosition: "right",
          targetPosition: "left",
        });
      });
      let newEdges = [];
      data.edges.forEach((edge) => {
        //if exist in newEdge a edge with the same source and target, then dont add it
        let exist = newEdges.some(
          (e) => e.source === edge.source && e.target === edge.target,
        );
        if (!exist) {
          let conditionsData = [];
          conditionsData.push(edge.data);
          edge.data.conditionsData = JSON.parse(JSON.stringify(conditionsData));
          //circular is if A->B and B->A
          edge.data.isCircular = data.edges.some(
            (e) => e.source === edge.target && e.target === edge.source,
          );
          newEdges.push({
            ...edge,
            animated: false,
            type: "smoothstep",
            markerEnd: {
              color: "#ccc",
              height: "25px",
              type: "arrowclosed",
              width: "25px",
            },
          });
        } else {
          let index = newEdges.findIndex(
            (e) => e.source === edge.source && e.target === edge.target,
          );
          newEdges[index].data.conditionsData.push(edge.data);
          newEdges[index].label = newEdges[index].label + "\n" + edge.label;
        }
      });

      setNodes(newNodes);
      setEdges(newEdges);
      let tempNodes = JSON.parse(JSON.stringify(newNodes));
      let tempEdges = JSON.parse(JSON.stringify(newEdges));
      let previous = JSON.parse(
        JSON.stringify({ nodes: tempNodes, edges: tempEdges }),
      );
      setPrevious(previous);
    });
  }, [agentSelected, customNodeStyles, setNodes, setEdges]);

  const getNode = (id, passedNodes = undefined) => {
    let nds = JSON.parse(JSON.stringify(passedNodes ? passedNodes : nodes));
    let result = {};

    for (let i = 0; i < nds.length; i++) {
      if (nds[i].id === id) {
        result = nds[i];
        break;
      }
    }
    return result;
  };

  const onConnect = (params) => {
    params.id = "e" + params.source + "-" + params.target;
    params.label = intents[0].name;
    params.data = {};
    params.data.label = intents[0].name;
    params.data.message = "";
    params.data.oid = "";
    params.data.type = "intent";
    params.type = "smoothstep";
    params.animated = false;
    params.data.conditions = [];
    params.data.intents = [intents[0].name];
    params.data.entity = {};
    params.data.cleanParams = [];
    params.data.presets = [];
    params.is_new = true;
    params.labelStyle = { fill: "white" };
    params.labelBgStyle = {
      fill: "#20212E",
      strokeWidth: "2",
      stroke: "#DFBD14",
    };
    params.style = {
      stroke: "#DFBD14",
      strokeWidth: "2px",
    };
    let sourceNode = getNode(params.source);
    let targetNode = getNode(params.target);
    params.source = sourceNode;
    params.target = targetNode;
    params.sourceHandle = sourceNode.data.label;
    params.targetHandle = targetNode.data.label;
    //if the source node is the same to target node, this if is for exit of method.
    if (sourceNode.id === targetNode.id) return;

    params.targetName = targetNode.data.label;
    params.sourceName = sourceNode.data.label;

    params.data.labelX =
      sourceNode.position.x +
      (targetNode.position.x - sourceNode.position.x) / 2;
    params.data.labelY =
      sourceNode.position.y +
      (targetNode.position.y - sourceNode.position.y) / 2;

    let edgeCount = edges.filter(
      (e) => e.source === params.source.id && e.target === params.target.id,
    ).length;

    let conditionsData = [];

    if (edgeCount > 0) {
      params.id = params.id + "-" + edgeCount;
      let filteredEdges = edges.filter(
        (e) => e.source === params.source.id && e.target === params.target.id,
      );
      let lastEdge = filteredEdges[filteredEdges.length - 1];
      conditionsData = conditionsData.concat(lastEdge.data.conditionsData);
      setEdgeSelectedTab(conditionsData.length);
    } else {
      params.is_new = true;
    }
    conditionsData.push(params.data);
    params.data.conditionsData = JSON.parse(JSON.stringify(conditionsData));
    setEditEdge(params);
    setEdges((eds) => addEdge(params, eds));
    setOpenEdgeModal(true);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  //onDrop is used to create a node at the moment of dropping the drag.
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow");
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: getNewNodeName(nodes) },
        style: customNodeStyles({ node_type: type }),
        is_new: true,
        sourcePosition: "right",
        targetPosition: "left",
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, nodes, customNodeStyles, setNodes],
  );

  const onNodeDragStop = (event, node) => {
    handleSaveNode(node);
  };

  useEffect(() => {
    getAgentNames();
    getEndpoints();
  }, []);

  useEffect(() => {
    if (agentSelected !== "") {
      getPages();
    }
  }, [agentSelected, getPages]);

  useEffect(() => {
    if (searchCoords.x !== 0 && searchCoords.y !== 0) {
      reactFlowInstance.setCenter(searchCoords.x, searchCoords.y, {
        duration: 800,
        zoom: 1,
      });
    }
  }, [searchCoords, reactFlowInstance]);

  const handleOpenNodeModal = (event, element) => {
    let editNode = element;
    let ep = endpoints.find((x) => x.name === editNode.data.label);
    if (ep === undefined) {
      ep = {
        name: "",
        type: "POST",
        host: "",
        route: "",
        params: [],
      };
    }
    editNode = setAgentTransferToNodeModal(editNode);
    setEditNode(editNode);
    setSelectedEndpoint(ep);
    setOpenNodeModal(true);
  };

  // If there is an agent transfer, we set the agent and the page to the selected element
  const setAgentTransferToNodeModal = (editNode) => {
    const noneElement = { name: "", display_name: "None" };

    // Construct transfer agent select
    let tempAgentsArray = JSON.parse(JSON.stringify(agentNames));
    let currentAgent = tempAgentsArray.find(
      (agent) => agent.name === agentSelected,
    ); // Remove current agent from list of agents to transfer to

    tempAgentsArray.splice(tempAgentsArray.indexOf(currentAgent), 1);
    tempAgentsArray.unshift(noneElement); // Add None item to be able to clear the component
    editNode.data.transfer_agent = [].concat(tempAgentsArray);

    // Construct transfer agent page select
    let tempAgentsPagesArray = JSON.parse(JSON.stringify(pagesLean));
    tempAgentsPagesArray = tempAgentsPagesArray.filter((page) =>
      page.agent === editNode.data.transferToAgent?.agent
        ? editNode.data.transferToAgent.agent
        : null,
    );
    tempAgentsPagesArray.unshift(noneElement);
    editNode.data.transfer_agent_pages = [].concat(tempAgentsPagesArray);

    return editNode;
  };

  const handleClose = () => {
    setOpenNodeModal(false);
    setOpenEdgeModal(false);
    setEditEdge({ data: {} });
    setEditNode(null);
    setEdgeSelectedTab(0);
  };

  const handleSaveNode = (edit) => {
    let nds = JSON.parse(JSON.stringify(nodes));

    for (let i = 0; i < nds.length; i++) {
      if (nds[i].id === edit.id) {
        nds[i] = edit;
        nds[i].selected = false;
        nds[i].style = customNodeStyles(edit);
        nds[i].is_modified = true;
      }
    }

    setOpenNodeModal(false);
    setEditNode(null);
    setNodes(nds);
  };

  const getIntents = () => {
    get("/intents?agent=" + agentSelected)
      .then(async (data) => {
        // Remove intents that have a response since we can't use them to transition
        let intents = data.filter(
          (intent) => intent.response?.text?.length === 0,
        );
        setIntents(intents);
        setIntentsqr(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingIntents"), {
          variant: "error",
        });
      });
  };

  const getAllPages = () => {
    get("/pages/all")
      .then(async (data) => {
        setPagesLean(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingPagesForAgentTransfers"), {
          variant: "error",
        });
      });
  };

  const getEndpoints = () => {
    get("/endpoints")
      .then(async (data) => {
        setEndpoints(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingEndpoints"), {
          variant: "error",
        });
      });
  };

  const getTraces = async () => {
    const tracesFlowgraph = await get("/traces/getFlow?agent=" + agentSelected);
    setTraces(tracesFlowgraph);
  };

  const handleDelete = async () => {
    await remove("/traces/publish", {
      agent: agentSelected,
      collectionTraces: "flow",
    })
      .then((e) => {
        if (e.errors) {
          for (const err of e.errors) {
            enqueueSnackbar(err.message, {
              variant: "error",
            });
          }
        }
        getTraces();
        getPages();
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorDeleteTraces"), {
          variant: "error",
        });
      });
  };
  const handlePublish = async () => {
    await post("/traces/publishFlow?agent=" + agentSelected)
      .then(async () => {
        enqueueSnackbar(t("intents:newChangesPublish"), {
          variant: "success",
        });
        getPages();
        getTraces();
      })
      .catch((errorMessage) => {
        enqueueSnackbar(
          `${errorMessage} :` + t("flowgraph:errorPublishingFlowgraph"),
          {
            variant: "error",
          },
        );
      });
  };

  const handleDeleteNode = () => {
    onElementsRemove([editNode]);
  };

  const onElementsRemove = (elementsToRemove) => {
    let nds = JSON.parse(JSON.stringify(nodes));
    let eds = JSON.parse(JSON.stringify(edges));
    let tmpDeleteElements = JSON.parse(JSON.stringify(deleteElements));

    let tempNodes = JSON.parse(JSON.stringify(nodes));
    let tempEdges = JSON.parse(JSON.stringify(edges));
    let previous = JSON.parse(
      JSON.stringify({ nodes: tempNodes, edges: tempEdges }),
    );
    setPrevious(previous);

    for (let x = 0; x < nds.length; x++) {
      for (let i = 0; i < elementsToRemove.length; i++) {
        // Prevenir eliminar el estado inicial (input)
        if (
          elementsToRemove[i].id === nds[x].id &&
          elementsToRemove[i].node_type !== "input" &&
          elementsToRemove[i].node_type !== "output"
        ) {
          for (let j = 0; j < eds.length; j++) {
            if (
              elementsToRemove[i].id === eds[j].source ||
              elementsToRemove[i].id === eds[j].target
            ) {
              tmpDeleteElements.edges.push(eds[j]);
              eds.splice(j, 1);
            }
          }
          tmpDeleteElements.pages.push(elementsToRemove[i]);
          nds.splice(x, 1);
        }
      }
    }
    setNodes(nds);
    setEdges(eds);
    setOpenEdgeModal(false);
    setOpenNodeModal(false);
    setSelectedEndpoint(undefined);
    setDeleteElements(tmpDeleteElements);
  };

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "flows" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSaveGraph = () => {
    //TODO: When this function is done, check the traces (is_modified)
    post("/flows", {
      agent: agentSelected,
      elements: nodes.concat(edges),
      endpoints: endpoints,
      deleteElements: deleteElements,
    })
      .then(async (response) => {
        if (response) {
          enqueueSnackbar(t("flowgraph:graphSaved"), {
            variant: "success",
          });
          deleteIsModified();
          getTraces();
        } else {
          enqueueSnackbar(t("flowgraph:featureDisabled"), {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingPages"), {
          variant: "error",
        });
      });
  };

  const handleSaveEdge = (edit) => {
    let egs = JSON.parse(JSON.stringify(edges));
    let previous = JSON.parse(JSON.stringify({ nodes: nodes, edges: egs }));

    let index = egs.findIndex((x) => x.id === edit.id);
    if (index === -1) {
      // Verificar si el borde es circular antes de agregarlo
      edit.data.isCircular = egs.some(
        (e) => e.source === edit.target.id && e.target === edit.source.id,
      );
      egs.push(edit);
    } else {
      // Verificar si el borde es circular antes de actualizarlo
      let isCircular = egs.some(
        (e) => e.source === edit.target.id && e.target === edit.source.id,
      );
      egs[index] = {
        ...edit,
        label: edit.data.label,
        source: edit.source.id,
        target: edit.target.id,
        is_modified: true,
        data: {
          ...edit.data,
          isCircular: isCircular,
        },
      };
    }

    // Encuentra el otro borde que cumple la condición y actualiza su propiedad isCircular
    let otherEdgeIndex = egs.findIndex(
      (e) => e.source === edit.target.id && e.target === edit.source.id,
    );
    if (otherEdgeIndex !== -1) {
      egs[otherEdgeIndex] = {
        ...egs[otherEdgeIndex],
        data: {
          ...egs[otherEdgeIndex].data,
          isCircular: true,
        },
      };
    }

    setPrevious(previous);
    setEdges(egs);
    setOpenEdgeModal(false);
    setEditEdge({ data: {} });
    setEdgeSelectedTab(0);
  };

  const handleDeleteTab = (edit) => {
    let egs = JSON.parse(JSON.stringify(edges));
    let previous = JSON.parse(JSON.stringify({ nodes: nodes, edges: egs }));

    let index = egs.findIndex((x) => x.id === edit.id);
    edit.is_modified = true;
    egs[index] = edit;
    egs[index].source = edit.source.id;
    egs[index].target = edit.target.id;

    setPrevious(previous);
    setEdges(egs);
    setDeleteElements({
      pages: deleteElements.pages,
      edges: deleteElements.edges.concat(edit),
    });
  };

  const handleDeleteEdge = () => {
    if (editEdge.data.isCircular) {
      const inverseEdge = edges.find((edge) => edge.target === editEdge.source);

      if (inverseEdge) {
        // Aquí puedes eliminar la propiedad isCircular del edge inverso
        const index = edges.indexOf(inverseEdge);
        if (index > -1) {
          edges[index].data.isCircular = false;
        }
      }
    }

    reactFlowInstance.deleteElements({ edges: [editEdge] });
    setDeleteElements({
      pages: deleteElements.pages,
      edges: deleteElements.edges.concat(editEdge),
    });
    setOpenEdgeModal(false);
    setEditEdge({ data: {} });
  };

  const handleOpenEdgeModal = (event, element) => {
    element.data.conditionsData.map((condition) => {
      return JSON.parse(JSON.stringify(condition));
    });
    let selectedElement = JSON.parse(JSON.stringify(element));

    selectedElement.target = JSON.parse(
      JSON.stringify(reactFlowInstance.getNode(element.target)),
    );
    selectedElement.source = JSON.parse(
      JSON.stringify(reactFlowInstance.getNode(element.source)),
    );

    setOpenEdgeModal(true);
    setEditEdge(selectedElement);
  };

  const handleSearch = (event) => {
    if (event) {
      reactFlowInstance.setCenter(event.position.x, event.position.y, {
        duration: 800,
        zoom: 1,
      });
    }
  };

  /*TODO: This function should be removed in the future, as it is currently being used to keep the changes visible. 
  In the future, when pages are brought in with unapplied traces, it will no longer be necessary.*/
  const deleteIsModified = () => {
    //for edges and nodes delete data.is_modified
    let nds = JSON.parse(JSON.stringify(nodes));
    let egs = JSON.parse(JSON.stringify(edges));
    for (let i = 0; i < nds.length; i++) {
      delete nds[i].is_modified;
      delete nds[i].is_new;
    }
    for (let i = 0; i < egs.length; i++) {
      delete egs[i].is_modified;
      delete egs[i].is_new;
    }
    setNodes(nds);
    setEdges(egs);
    setDeleteElements({ pages: [], edges: [] });
  };

  return (
    <ReactFlowProvider>
      <div
        style={{ width: "100vw", height: "100vh", backgroundColor: "#141414" }}>
        <Bar
          agentSelected={agentSelected}
          setAgentSelected={setAgentSelected}
          agentNames={agentNames}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          onSave={handleSaveGraph}
          colors={colors}
          nodes={nodes}
          onSearch={handleSearch}
        />
        <EdgeModalTabsPanel
          open={openEdgeModal}
          onClose={handleClose}
          editEdgeProps={editEdge}
          previous={previous}
          onSave={handleSaveEdge}
          onDelete={handleDeleteEdge}
          intents={intents}
          edges={edges}
          pages={nodes}
          userPermissions={userPermissions}
          propsSelectedTab={edgeSelectedTab}
          onDeleteTab={(edge) => handleDeleteTab(edge)}
        />
        <NodeModal
          open={openNodeModal}
          editNodeProps={editNode}
          nodes={nodes}
          onClose={handleClose}
          previous={previous}
          onSave={handleSaveNode}
          onDelete={handleDeleteNode}
          pagesLean={pagesLean}
          endpoints={endpoints}
          userPermissions={userPermissions}
          intents={intentsqr}
          endpointProps={selectedEndpoint}
        />
        <ReactFlow
          className="reactflow-v2"
          nodes={nodes}
          edges={edges}
          edgeTypes={edgeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDragStop={onNodeDragStop}
          onInit={setReactFlowInstance}
          onConnect={onConnect}
          onNodeDoubleClick={handleOpenNodeModal}
          onEdgeDoubleClick={handleOpenEdgeModal}
          deleteKeyCode={null}
          selectionMode={"partial"}
          onNodesDelete={onElementsRemove}>
          <Background color="#141414" variant="dots" />
        </ReactFlow>
        <TracesHistory
          handleDelete={handleDelete}
          handlePublish={handlePublish}
          agentPages={agentSelected}
          traces={traces}
        />
      </div>
    </ReactFlowProvider>
  );
}
