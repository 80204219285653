import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography, Grid, Checkbox } from "@mui/material";

const StepTwo = ({  onChange, setPermissions, permissions }) => {
  const { t } = useTranslation("permissions");
  const hiddenPermissions = ["holidays", "permissions", "noMatchIgnore"];

  useEffect(() => {
    onChange(permissions);
  }, [permissions, onChange]);

  function handlePermissionChange(index, newAccessLevel) {
    setPermissions((prevPermissions) => {
      const newPermissions = [...prevPermissions];
      newPermissions[index].fullAccess = newAccessLevel;

      if (newPermissions[index].name === "workingDays") {
        newPermissions.forEach((permission) => {
          if (permission.name === "holidays") {
            permission.fullAccess = newAccessLevel;
          }
        });
      }

      if (newPermissions[index].name === "users") {
        newPermissions.forEach((permission) => {
          if (permission.name === "permissions") {
            permission.fullAccess = newAccessLevel;
          }
        });
      }

      if (newPermissions[index].name === "noMatch") {
        newPermissions.forEach((permission) => {
          if (permission.name === "noMatchIgnore") {
            permission.fullAccess = newAccessLevel;
          }
        });
      }

      if (newPermissions[index].name === "config") {
        newPermissions.forEach((permission) => {
          if (permission.name === "clients2") {
            permission.fullAccess = newAccessLevel;
          }
        });
      }

      return newPermissions;
    });
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="subtitle1"
            sx={{
              paddingLeft: "5%",
            }}>
            {t("name")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t("fullAccess")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t("readOnly")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t("noAccess")}</Typography>
        </Grid>
      </Grid>
      {permissions
        .filter((permission) => !hiddenPermissions.includes(permission.name))
        .map((permission, index) => (
          <Grid key={index}
            container
            sx={{
              backgroundColor:
                index % 2 === 0
                  ? "permissionsGrid.backgroundColor"
                  : "background.default",
            }}>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                sx={{
                  paddingLeft: "5%",
                }}>
                {t(`${permission.name}`)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={permission.fullAccess}
                onChange={() => {
                  if (permission.fullAccess !== true) {
                    handlePermissionChange(index, true);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={permission.fullAccess === false}
                onChange={() => {
                  if (permission.fullAccess !== false) {
                    handlePermissionChange(index, false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={permission.fullAccess === null}
                onChange={() => {
                  if (permission.fullAccess !== null) {
                    handlePermissionChange(index, null);
                  }
                }}
              />
            </Grid>
          </Grid>
        ))}
    </Box>
  );
};

export default StepTwo;
