const llmModelsNames = [
  {
    id: "openai_gpt4",
    name: "OpenAI - GPT-4",
  },
  {
    id: "openai_gpt4o",
    name: "OpenAI - GPT-4o",
  },
  {
    id: "openai_gpt4o_mini",
    name: "OpenAI - GPT-4o Mini",
  },
  {
    id: "openai_gpt-3.5-turbo-0125",
    name: "OpenAI - GPT-3.5 Turbo",
  },
  {
    id: "groq_llama-3.1-70b-versatile",
    name: "Groq - LLaMA 3.1 70b",
  },
  {
    id: "groq_llama-3.1-8b-instant",
    name: "Groq - LLaMA 3.1 8b",
  },
  {
    id: "groq_llama3-8b-8192",
    name: "Groq - LLaMA 3 8b",
  },
  {
    id: "groq_llama3-70b-8192",
    name: "Groq - LLaMA 3 70b",
  },
  {
    id: "groq_mixtral-8x7b-32768",
    name: "Groq - Mixtral 8x7b",
  },
  {
    id: "groq_gemma-7b-it",
    name: "Groq - Gemma 7b",
  },
  {
    id: "sagemaker_llama3",
    name: "SageMaker - Llama 3",
  },
  {
    id: "sagemaker_llama2",
    name: "SageMaker - Llama 2",
  },
  {
    id: "sagemaker_mixtral",
    name: "SageMaker - Mixtral",
  },
  {
    id: "sagemaker_claude",
    name: "SageMaker - Claude",
  },
];

const embeddingsModelNames = [
  {
    name: "OpenAI",
    id: "openai",
  },
  {
    name: "HuggingFace",
    id: "huggingface",
  },
];

module.exports = {
  llmModelsNames,
  embeddingsModelNames};
